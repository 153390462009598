import React from "react";
import classNames from "classnames";

import Heading from "$ui/heading";
import Point from "$ui/point";

import { isBrowser } from "$services/auth";

const Service = ({ title, children, classNameA }) => (
  <div
    className={classNames(
      "bg-gray-100 px-5 py-10 rounded-lg h-min shadow-fat min-h-[30rem] hover:scale-105 transition-all duration-300 cursor-pointer"
    ) }
    style={
      isBrowser() && document.documentElement.clientWidth > 900
        ? {
            backdropFilter: "blur(16px) saturate(200%)",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
          }
        : {}
    }
  >
    <Heading
      variant="tertiary"
      className=" font-bold md:text-2xl text-blue-500 "
    >
      {title}
    </Heading>

    <ul className="space-y-5 mt-12 md:text-lg font-medium">
      {children.map((c, i) => (
        <Point variant="black" key={i}>
          {c}
        </Point>
      ))}
    </ul>
  </div>
);

export default Service;
