import React from 'react';

import ArrowLeft from '$assets/arrowleft.svg';
import ArrowRight from '$assets/arrowright.svg';

const Control = ({ onClick, label, align = 'left' }) => (
  <button
    className={`absolute flex justify-center items-center h-12 w-12 bg-gray-300 opacity-70 rounded-full top-1/2 transition-opacity ${
      align === 'left' ? 'left-8' : 'right-8'
    } hover:opacity-100 phone:h-8 phone:w-8`}
    onClick={onClick}>
    {align === 'left' ? (
      <ArrowLeft className='phone:h-4 phone:w-4' />
    ) : (
      <ArrowRight className='phone:h-4 phone:w-4' />
    )}
  </button>
);

export default Control;
