import React from 'react';

const Slide = ({ translate, children }) => (
  <div
    className='absolute w-full h-full top-0 left-0 transition-transform duration-1000'
    style={{ transform: `translateX(${translate}%)` }}>
    {children}
  </div>
);

export default Slide;
