import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import Slide from './slide';
import Control from './control';

const Slider = ({
  autoPlay,
  autoPlayDirection,
  children,
  height,
  hideControls,
  className
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const MAX_SLIDES = children?.length;

  useEffect(() => {
    if (autoPlay) return;

    const timer = setTimeout(() => {
      if (autoPlayDirection === 'left')
        setCurrentSlide(
          currentSlide - 1 >= 0 ? currentSlide - 1 : MAX_SLIDES - 1
        );
      else setCurrentSlide((currentSlide + 1) % MAX_SLIDES);
    }, 3000);

    return () => clearTimeout(timer);
  }, [autoPlay, autoPlayDirection, currentSlide, MAX_SLIDES]);

  return (
    <div
      className={classNames(
        `relative overflow-x-hidden ${children?.length ? height : 'h-0'}`,
        className
      )}>
      {children ? (
        children.length ? (
          children.map((child, slideNumber) => (
            <Slide
              key={slideNumber}
              translate={100 * (slideNumber - currentSlide)}>
              {child}
            </Slide>
          ))
        ) : (
          children
        )
      ) : (
        <></>
      )}

      {hideControls || !children?.length ? (
        <></>
      ) : (
        <>
          <Control
            align='left'
            onClick={() =>
              setCurrentSlide(
                currentSlide - 1 >= 0 ? currentSlide - 1 : MAX_SLIDES - 1
              )
            }
          />
          <Control
            align='right'
            onClick={() => setCurrentSlide((currentSlide + 1) % MAX_SLIDES)}
          />
        </>
      )}
    </div>
  );
};

export default Slider;
