import React from "react";
import { Helmet } from "react-helmet";

import WhoAreWe from "$components/whoWeAreAbout";

import Section from "$ui/section";
import WhiteBox from "$ui/whiteBox";
import Point from "$ui/point";
import Slider from "$components/slider";
import Heading from "$ui/heading";
import Service from "$components/service";

import slide1Img from "$images/about/slide-1.jpg";
import slide2Img from "$images/about/slide-2.jpg";
import slide3Img from "$images/about/slide-3.jpg";
import slide4Img from "$images/about/slide-4.jpg";
import slide5Img from "$images/about/slide-5.jpg";
import slide6Img from "$images/about/slide-6.jpg";
import B1 from "$images/banner-1.png";
import B2 from "$images/banner-2.png";
import B3 from "$images/banner-3.png";

const AboutPage = () => (
  <>
    <Helmet>
      <title>TheCRO &mdash; About</title>
    </Helmet>

    <header className="pt-32 pb-16 px-10 phone:px-6 tab-port:pb-0">
      <Slider height="h-[35rem]" className="rounded-xl">
        <img
          src={B1}
          alt="ILLUSTRAION"
          className="h-full w-full object-contain"
        />
        <img
          src={B2}
          alt="ILLUSTRAION"
          className="h-full w-full object-contain"
        />
        <img
          src={B3}
          alt="ILLUSTRAION"
          className="h-full w-full object-contain"
        />
        {/* <img
          src={slide1Img}
          alt="ILLUSTRAION"
          className="h-full w-full object-cover"
        /> */}
        {/* <img
          src={slide2Img}
          alt="ILLUSTRAION"
          className="h-full w-full object-cover"
        />
        <img
          src={slide3Img}
          alt="ILLUSTRAION"
          className="h-full w-full object-cover"
        />

        <img
          src={slide4Img}
          alt="ILLUSTRAION"
          className="h-full w-full object-cover"
        />

        <img
          src={slide5Img}
          alt="ILLUSTRAION"
          className="h-full w-full object-cover"
        />

        <img
          src={slide6Img}
          alt="ILLUSTRAION"
          className="h-full w-full object-cover"
        /> */}
      </Slider>
    </header>

    <main>
      <Section withAbstract={true}>
        <WhoAreWe />
      </Section>
      <Section withAbstract={true} className="">
        <div className="grid grid-cols-4 gap-x-6 gap-y-10 mt-20 tab-land:gap-x-16 tab-port:grid-cols-none tab-port:justify-center">
          <Service  title="Problems we are solving">
            <span>Consumer complaints not resolved</span>
            <span>Lengthy and complex process</span>
            <span>Inefficient and time consuming</span>
            <span>Limited access to resources</span>
          </Service>

          <Service title="Our Goal">
            <span>Empowering consumer to voice concerns</span>
            <span>Facilitating prompt and efficient resolution</span>
            <span>Promoting corporate reasonability</span>
            <span>Advocating for consumer rights</span>
          </Service>

          <Service title="Our Solution">
            <span>Online grievance redressal platform</span>
            <span>User Friendly Interface</span>
            <span>Streamlined complaint filing process</span>
            <span>Quick and efficient resolution</span>
            <span>Access to element resources</span>
          </Service>
          <Service title="Key Features">
            <span>Easy registration and login</span>
            <span>Self service and user friendly portal</span>
            <span>Secure complaint filing system</span>
            <span>Automated complaint tracking</span>
            <span>Resource center with FAQs</span>
          </Service>

          <div className="h-20 phone:hidden"></div>
        </div>
      </Section>

      {/* <Section className='pt-48'>
        <Heading className='mb-32'>Meet our amazing team</Heading>
        <div className='grid grid-cols-3 justify-center gap-x-20 gap-y-36 tab-port:grid-cols-none'>
          <Founder name='Amin Painter' description='CEO' image={slide1Img} />
          <Founder name='Amin Painter' description='CEO' image={slide1Img} />
          <Founder name='Amin Painter' description='CEO' image={slide1Img} />
        </div>
      </Section> */}

      {/* <Section withAbstract={true}>
        <WhiteBox>
          <Heading>Objectives</Heading>

          <ul className='grid grid-cols-2  gap-x-20 gap-y-8 mt-20 tab-port:grid-cols-none'>
            <Point variant='primary'>Organize and aware consumers</Point>
            <Point variant='primary'>
              Protect and publicize all the rights of consumer.
            </Point>
            <Point variant='primary'>
              Execute the programs related to consumer education, their rights
              and their responsibilities, with the help of government and
              administration.
            </Point>
            <Point variant='primary'>
              To protect consumers interests, their basic needs and rights such
              as health, education, water, electricity, roads, entertainment,
              catering, traffic, etc.
            </Point>
            <Point variant='primary'>
              Operate consumer information center in all districts of India with
              the help of government
            </Point>
            <Point variant='primary'>
              Make best effort to stop monopolistic, restrictive and unfair
              trade practices.
            </Point>
            <Point variant='primary'>
              Solve problems of general consumers and our members.
            </Point>
            <Point variant='primary'>
              Give and receive moral support for the consumer interest to other
              organizations while working under the rules of the organization.
            </Point>
            <Point variant='primary'>
              Organizing and conducting consumer's related programs to get aid
              andgrants from the central government, state governments, agencies
              and organizations. Solve problems of general consumers and our
              members.
            </Point>
            <Point variant='primary'>
              Through the organization implement and operate the various
              consumer related schemes of Central Government and State
              Governments.
            </Point>
            <Point variant='primary'>
              Through the organization legally present the consumer problems and
              complaints before the Consumer Forum and the Commission.
            </Point>
          </ul>
        </WhiteBox>
      </Section> */}
    </main>
  </>
);

export default AboutPage;
